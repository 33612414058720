import { Button, Space, Switch, Tooltip } from "antd";
import { BasilUserIcon, BlockPlayerIcon } from "common/Icons";
import CustomInput from "common/Input";
import MultiSelect from "common/MultiSelect";
import Permitted from "common/Permitted";
import Select from "common/Select";
import { mockedUnitIds } from "utils/models";
import { PLAYER_TYPES, STATUS } from "../constants";

export const modalTypes = {
	openedModal: "chatPlayers",
	block: "block",
	influence: "Influence",
	reset: "reset"
};

export const userFilters = [
	{
		Component: MultiSelect,
		name: "chatNames",
		label: "Chat Name",
		componentProps: {
			placeholder: "Type",
			withSearch: true
		}
	},
	{
		Component: MultiSelect,
		name: "partners",
		label: "Partners ",
		componentProps: {
			placeholder: "Select Partner(s)",
			withSearch: true
		}
	},
	{
		Component: MultiSelect,
		name: "gameTypes",
		label: "Games  ",
		componentProps: {
			placeholder: "Select Game(s)",
			withSearch: true
		}
	},
	{
		Component: CustomInput,
		name: "partnerPlayerId",
		label: "Partner Player ID",
		componentProps: {
			placeholder: "Type",
			withSearch: false
		}
	},
	{
		Component: Select,
		name: "playerTypes",
		label: "Player Type",
		componentProps: {
			placeholder: "Select Player Type",
			withSearch: true
		}
	},
	{
		Component: Select,
		name: "statuses",
		label: "Status",
		componentProps: {
			placeholder: "Select Status",
			withSearch: true
		}
	}
];

export const mappedColumns = openModal => [
	{
		key: "1",
		title: "Chat Name",
		dataIndex: "chatName",
		width: 180
	},
	{
		key: "2",
		title: "Partner Name",
		dataIndex: "partnerName",
		width: 180
	},
	{
		key: "3",
		title: "Nickname",
		dataIndex: "nickName",
		width: 200
	},
	{
		key: "4",
		title: "Player Type",
		dataIndex: "type",
		width: 200,
		render: type => PLAYER_TYPES[type]
	},
	{
		key: "5",
		title: "Profanity Count",
		dataIndex: "profanityCount",
		width: 180
	},
	{
		key: "6",
		title: "Status",
		dataIndex: "status",
		width: 180,
		render: status => STATUS[status]
	},
	{
		key: "7",
		title: "Actions",
		dataIndex: "status",
		width: 150,
		align: "right",
		fixed: "right",
		render: function Axpanded(status, data) {
			const { type, resetNickName } = data;
			return (
				<Permitted unitId={mockedUnitIds.ChatPlayers} parentUnitId={mockedUnitIds.Chat} actionId={2}>
					<Space size={16} className="align-center justify-end">
						<Tooltip title="Reset Nickname">
							<Button
								style={{ color: resetNickName ? "#4689FF" : "#999999" }}
								onClick={() => openModal(modalTypes.openedModal, { ...data, eventType: modalTypes.reset })}
								shape="circle"
								className="mr-8 muted-text"
								type="link"
								icon={<BasilUserIcon />}
							/>
						</Tooltip>
						<Tooltip title={status === 1 ? "UnBlock" : "Block"}>
							<Button
								style={{ color: status === 0 ? "#999999" : "#4689FF" }}
								onClick={() => openModal(modalTypes.openedModal, { ...data, eventType: modalTypes.block })}
								shape="circle"
								className="mr-8 muted-text"
								type="link"
								icon={<BlockPlayerIcon />}
							/>
						</Tooltip>
						<Tooltip title={type === 1 ? "Make Regular" : "Make Influncer"}>
							<Switch
								checked={type === 1}
								onChange={() => openModal(modalTypes.openedModal, { ...data, eventType: modalTypes.influence })}
								className="flex"
								size="small"
								style={{ width: 34 }}
							/>
						</Tooltip>
					</Space>
				</Permitted>
			);
		}
	}
];
