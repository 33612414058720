import PropTypes from "prop-types";
import { Button, Modal, notification } from "antd";
import { useEffect, useState, useCallback } from "react";
import { useUploadImageMutation } from "app/services/ruleApiSlice";
import { useUpdateAvatarsMutation, useUpdateStickersMutation } from "app/services/chutApiSlice";
import NoData from "common/NoData";
import UploadFile from "../UploadFile";
import classes from "./styles.module.less";

function StickersAvatars({ type, files, selectedFiltersGroup }) {
	const [fileList, setFileList] = useState([]);
	const [previewImage, setPreviewImage] = useState(null);
	const [uploadImage] = useUploadImageMutation();
	const [updateStickers] = useUpdateStickersMutation();
	const [updateAvatars] = useUpdateAvatarsMutation();

	useEffect(() => {
		if (files) {
			setFileList(files);
		}
	}, [files]);

	const handlePreview = useCallback(previewImg => setPreviewImage(previewImg), []);
	const handleCancel = () => setPreviewImage(null);

	const cancelChanges = () => {
		setFileList(files);
	};

	const saveChanges = () => {
		const request = type === "avatars" ? updateAvatars : updateStickers;
		let requestData;
		if (type === "avatars") {
			requestData = {
				[type]: fileList.map(item => ({
					...item,
					partnerId: selectedFiltersGroup.partners === "default" ? null : selectedFiltersGroup.partners
				}))
			};
		} else {
			requestData = {
				[type]: fileList.map(item => ({
					...item,
					partnerId: selectedFiltersGroup.partners,
					gameTypeId: selectedFiltersGroup.gameTypes === "default" ? null : selectedFiltersGroup.gameTypes
				}))
			};
		}
		request(requestData).then(res => {
			if (res.data?.hasError === false) {
				notification.success({ message: "Successfuly updated!" });
			}
		});
	};

	return (
		<>
			<div className={classes.container}>
				{fileList.length ? (
					<>
						<div className={classes.list}>
							{fileList.map(item => (
								<div className={classes.col} key={item.id}>
									<span className={classes.name}>{item.name}</span>
									<UploadFile
										imageData={item}
										classes={classes}
										uploadImage={uploadImage}
										handlePreview={handlePreview}
										setFileList={setFileList}
									/>
								</div>
							))}
						</div>
						<Modal open={!!previewImage} footer={null} title={previewImage?.title} onCancel={handleCancel}>
							<div className={classes.imageWrapper}>
								<img
									alt="example"
									style={{
										width: "100%",
										height: "100%",
										objectFit: "contain",
										maxHeight: "70vh"
									}}
									src={previewImage?.src}
								/>
							</div>
						</Modal>
					</>
				) : (
					<NoData />
				)}
			</div>

			{fileList.length && (
				<div className={classes.buttons}>
					<Button type="link" onClick={cancelChanges}>
						Cancel
					</Button>
					<Button type="primary" onClick={saveChanges}>
						Save
					</Button>
				</div>
			)}
		</>
	);
}

StickersAvatars.defaultProps = {
	type: "stickers",
	files: [],
	selectedFiltersGroup: {}
};

StickersAvatars.propTypes = {
	type: PropTypes.oneOf(["stickers", "avatars"]),
	files: PropTypes.array,
	selectedFiltersGroup: PropTypes.object
};

export default StickersAvatars;
