import { memo, useEffect, useState } from "react";
import { Select } from "antd";
import PropTypes from "prop-types";
import ErrorBoundary from "../ErrorBoundary";

function CustomSelect({
	options,
	style,
	className,
	placeholder,
	onChange,
	name,
	data,
	disabled,
	anotherRow,
	withSearch,
	status,
	form,
	allowClear,
	isRequired,
	showEmail,
	defaultValue,
	size
}) {
	if (!Array.isArray(options)) return null;
	const [list, setList] = useState(options);

	useEffect(() => {
		setList(options);
	}, [options]);

	const handleChange = e => {
		onChange({ value: e, name }, "select", form);
		setList(options);
	};

	const onSearch = val => {
		const value = val.trim()?.toLowerCase();
		if (value) {
			setList(
				options.filter(item => item.name?.toLowerCase()?.includes(value) || item.text?.toLowerCase()?.includes(value))
			);
		} else {
			setList(options);
		}
	};

	let searchOptions = {};
	if (withSearch) {
		searchOptions = {
			showSearch: true,
			onSearch
		};
	}

	return (
		<ErrorBoundary>
			<Select
				placeholder={placeholder}
				className={className}
				style={style}
				size={size}
				showArrow
				disabled={disabled}
				onChange={handleChange}
				value={data || undefined}
				optionFilterProp="children"
				getPopupContainer={trigger => trigger.parentElement}
				status={status}
				allowClear={allowClear && !isRequired}
				{...(defaultValue ? { defaultValue } : {})}
				{...searchOptions}
			>
				{Object.keys(anotherRow)?.length > 0 ? (
					<>
						<Select.Option key={anotherRow.id || anotherRow.value} value={anotherRow.id}>
							{anotherRow.name}
						</Select.Option>
						<Select.Option className="ant-dropdown-menu-item-divider" />
					</>
				) : null}
				{list.map(option => {
					return (
						<Select.Option key={option.id || option.value} value={option.id || option.value} disabled={option.disabled}>
							{option.name || option.text}
							{showEmail && option.email && (
								<span style={{ display: "block", fontSize: "0.8em", lineHeight: 1, color: "#999" }}>
									({option.email})
								</span>
							)}
						</Select.Option>
					);
				})}
			</Select>
		</ErrorBoundary>
	);
}

CustomSelect.defaultProps = {
	placeholder: "",
	className: "",
	style: {},
	options: [],
	data: "",
	name: "",
	onChange: () => {},
	disabled: false,
	anotherRow: {},
	withSearch: false,
	status: "",
	form: { setFieldValue: () => {} },
	allowClear: true,
	isRequired: false,
	showEmail: false,
	defaultValue: "",
	size: "middle"
};

CustomSelect.propTypes = {
	placeholder: PropTypes.string,
	className: PropTypes.string,
	name: PropTypes.string,
	style: PropTypes.object,
	options: PropTypes.array,
	data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	anotherRow: PropTypes.object,
	withSearch: PropTypes.bool,
	status: PropTypes.oneOf(["", "error", "worning"]),
	form: PropTypes.any,
	allowClear: PropTypes.bool,
	isRequired: PropTypes.bool,
	showEmail: PropTypes.bool,
	size: PropTypes.bool,
	defaultValue: PropTypes.string
};

export default memo(CustomSelect);
