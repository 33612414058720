import { useModal } from "hooks/useModal";
import { useMemo } from "react";
import { useFilters } from "hooks/useFilters";
import HeaderFilters from "common/HeaderFilters";
import TableWithPagination from "common/TableWithPagination";
import CustomModal from "common/Modal";
import { useTable } from "hooks/useTable";
import { notification } from "antd";
import {
	useBlockPlayerMutation,
	useGetChatPlayersListFiltersQuery,
	useGetChatPlayersListMutation,
	useMakeInfluencerMutation,
	useResetPlayerNickNameMutation
} from "app/services/chutApiSlice";
import { mappedColumns, modalTypes, userFilters } from "./constants";
import { ModalContent } from "./ModalContent";

function ChatPlayers() {
	const [modals, openModal, closeModal] = useModal();
	const mappedColumnsMemo = useMemo(() => mappedColumns(openModal), []);
	const openedModal = modals[modalTypes.openedModal];

	const [filtersQueryData, { selectedFiltersGroup, setSelectedFiltersGroup }, handleSelectChange] = useFilters(
		useGetChatPlayersListFiltersQuery
	);
	const [getTableData, { isLoading, data, pageIndex, handlePageChange, pageSize, reset }] = useTable(
		useGetChatPlayersListMutation,
		filtersQueryData
	);
	const [resetPlayerNickName, { isLoading: resetLoading }] = useResetPlayerNickNameMutation();
	const [blockPlayer, { isLoading: blockLoading }] = useBlockPlayerMutation();
	const [makeInfluencer, { isLoading: influencerLoading }] = useMakeInfluencerMutation();

	const handleReset = () => {
		reset();
		setSelectedFiltersGroup({});
	};

	const handleCloseModal = () => closeModal(modalTypes.openedModal);

	const handleApply = () => {
		const { chatNames, partners, gameTypes, partnerPlayerId, playerTypes, statuses } = selectedFiltersGroup;
		getTableData({
			chatIds: chatNames,
			partnerIds: partners,
			gameTypeIds: gameTypes,
			playerType: +playerTypes || undefined,
			playerStatus: +statuses || undefined,
			partnerPlayerId
		});
	};

	const applayModalActions = () => {
		let request;
		let requestData;
		switch (openedModal.eventType) {
			case modalTypes.block: {
				request = blockPlayer;
				requestData = {
					id: openedModal.id,
					block: openedModal.status === 0
				};
				break;
			}
			case modalTypes.influence: {
				request = makeInfluencer;
				requestData = {
					id: openedModal.id,
					influencer: openedModal.type === 0
				};
				break;
			}
			default: {
				request = resetPlayerNickName;
				requestData = {
					id: openedModal.id,
					resetNickName: !openedModal.resetNickName
				};
			}
		}
		request(requestData).then(res => {
			if (res.data.hasError === false) {
				handleApply();
				closeModal(modalTypes.openedModal);
				notification.success({
					message: "Successfuly updated!"
				});
			}
		});
	};

	return (
		<section className="container-section">
			<HeaderFilters
				withSubmit
				withReset
				filtersQueryData={filtersQueryData}
				filtersComponentData={userFilters}
				handleChange={handleSelectChange}
				handleReset={handleReset}
				handleApply={handleApply}
				selectedFiltersGroup={selectedFiltersGroup}
				disabledApplay={isLoading}
			/>

			<TableWithPagination
				isLoading={isLoading}
				columns={mappedColumnsMemo}
				data={data?.data}
				totalCount={data?.totalCount}
				pageSize={pageSize}
				currentPage={pageIndex}
				setCurrentPage={handlePageChange}
				scroll={{ y: 600 }}
				rowKey="id"
			/>

			{openedModal?.open && (
				<CustomModal
					open
					onOk={applayModalActions}
					onCancel={handleCloseModal}
					okText="Yes"
					centered
					modalWidth={440}
					body={<ModalContent {...openedModal} />}
					okButtonProps={{ disabled: resetLoading || blockLoading || influencerLoading }}
				/>
			)}
		</section>
	);
}

export default ChatPlayers;
