import { useMemo, memo, useState, useEffect, useRef } from "react";
import { Select, Tree } from "antd";
import PropTypes from "prop-types";
import ErrorBoundary from "../ErrorBoundary";
import "./index.less";

function TreeSelect({
	placeholder,
	onChange,
	style,
	className,
	options,
	data,
	disabled,
	maxTagCount,
	withSearch,
	name,
	status,
	form
}) {
	if (!Array.isArray(options)) return null;
	const [value, setValue] = useState("");
	const [checkedKeys, setCheckedKeys] = useState([]);
	const treeRef = useRef();

	useEffect(() => {
		setCheckedKeys(data);
	}, [data]);

	const memoData = useMemo(() => {
		const partnerList = [];
		options.forEach(item => {
			item.partners?.forEach(elem => {
				if (data.includes(elem.id)) {
					partnerList.push({
						key: elem.id,
						label: elem.name,
						...elem
					});
				}
			});
		});
		return partnerList;
	}, [data, options]);

	const memoPartners = useMemo(() => {
		return [
			{
				title: "Select All",
				key: "all",
				children: options.map(item => ({
					title: item.name,
					key: `instance_${item.id}`,
					children:
						item.partners.map(partner => ({
							key: partner.id,
							title: partner.name
						})) || []
				}))
			}
		];
	}, [options]);

	useEffect(() => {
		const val = value.toLowerCase();
		const list = treeRef.current?.querySelectorAll(".ant-tree-title");
		const findedList = treeRef.current?.querySelectorAll(".finded");

		findedList?.forEach(item => item.classList.remove("finded"));

		if (val && list?.length) {
			const firstTitle = Array.from(list).find(ttt => ttt.innerText.toLowerCase().match(val));
			if (firstTitle) {
				firstTitle.classList.add("finded");
				firstTitle.scrollIntoView();
			} else {
				treeRef.current?.scrollTo(0, 0);
			}
		} else {
			treeRef.current?.scrollTo(0, 0);
		}
	}, [value, treeRef.current]);

	const onCheck = checkedKeysValue => {
		const partnerIds = checkedKeysValue.filter(item => typeof item === "number");
		setCheckedKeys(checkedKeysValue);
		onChange({ name, value: partnerIds }, "tree", form);
		setValue("");
	};

	let searchOptions = {};
	if (withSearch) {
		searchOptions = {
			showSearch: true,
			onSearch: setValue
		};
	}

	return (
		<ErrorBoundary>
			<Select
				name={name}
				mode="multiple"
				placeholder={placeholder}
				className={`${className} custom-multi-select multi-select-tree`}
				style={style}
				size="middle"
				showArrow
				maxTagCount={maxTagCount}
				getPopupContainer={trigger => trigger.parentElement}
				disabled={disabled}
				value={memoData}
				status={status}
				{...searchOptions}
				// eslint-disable-next-line react/no-unstable-nested-components
				dropdownRender={() => (
					<div ref={treeRef} style={{ maxHeight: 240, overflow: "hidden auto" }}>
						<Tree
							checkable
							defaultExpandAll
							onCheck={onCheck}
							checkedKeys={checkedKeys}
							treeData={memoPartners}
							rootClassName={`tree ${value.length > 0 ? "tree-with-search" : ""}`}
							blockNode
						/>
					</div>
				)}
			/>
		</ErrorBoundary>
	);
}

TreeSelect.defaultProps = {
	placeholder: "Select",
	className: "",
	name: "",
	style: {},
	options: [],
	data: [],
	onChange: () => {},
	disabled: false,
	maxTagCount: 1,
	withSearch: true,
	status: "",
	form: { setFieldValue: () => {} }
};

TreeSelect.propTypes = {
	placeholder: PropTypes.string,
	className: PropTypes.string,
	name: PropTypes.string,
	style: PropTypes.object,
	options: PropTypes.array,
	data: PropTypes.array,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	maxTagCount: PropTypes.number,
	withSearch: PropTypes.bool,
	status: PropTypes.string,
	form: PropTypes.object
};

export default memo(TreeSelect);
