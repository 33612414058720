import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { rtkQueryErrorMiddleware } from "./errorMiddleware";

// RTK Slicies
import { currenciesSlice } from "./slices/currenciesSlice";

// RTK Query Slicies
import { rulesApiSlice } from "./services/ruleApiSlice";
import { translationApiSlice } from "./services/translationApiSlice";
import { partnersApiSlice } from "./services/partnersApiSlice";
import { gamesApiSlice } from "./services/gamesApiSlice";
import { drawApiSlice } from "./services/drawApiSlice";
import { permissionApiSlice } from "./services/permissionApiSlice";
import { bonusApiSlice } from "./services/bonusApiSlice";
import { accountApiSlice } from "./services/accountApiSlice";
import { operatorApiSlice } from "./services/operatorApiSlice";
import { dashboardApiSlice } from "./services/dashboardApiSlice";
import { logsSlice } from "./services/logsSlice";
import { systemSettingsSlice } from "./services/systemSettingsSlice";
import { currencyApiSlice } from "./services/currencyApiSlice";
import { leaderboardApiSlice } from "./services/leaderboardApiSlice";
import { cashbackApiSlice } from "./services/cashbackApiSlice";
import { reportingApiSlice } from "./services/reportingApiSlice";
import { chutApiSlice } from "./services/chutApiSlice";
import { campaignApiSlice } from "./services/campaignApiSlice";
import { riskPlayersListApiSlice } from "./services/riskPlayersListApiSlice";
import { storageManagementApiSlice } from "./services/storageManagementApiSlice";
import { recipientProfilersApiSlice } from "./services/recipientProfilersApiSlice";
import { recipientListsApiSlice } from "./services/recipientListsApiSlice";
import { cachedApiSlice } from "./services/cachedApiSlice";
import { playLinkApiSlice } from "./services/playLinkApiSlice";
import { licenseSlice } from "./services/licenseSlice";
import { alertsApiSlice } from "./services/alertsApiSlice";
import { maxWinnersApiSlice } from "./services/maxWinnersApiSlice";
import { gameSkinningApiSlice } from "./services/gameSkinningApiSlice";
import { rtpMonitoringSlice } from "./services/rtpMonitoringSlice";

export const store = configureStore({
	reducer: {
		[rulesApiSlice.reducerPath]: rulesApiSlice.reducer,
		[translationApiSlice.reducerPath]: translationApiSlice.reducer,
		[partnersApiSlice.reducerPath]: partnersApiSlice.reducer,
		[gamesApiSlice.reducerPath]: gamesApiSlice.reducer,
		[drawApiSlice.reducerPath]: drawApiSlice.reducer,
		[permissionApiSlice.reducerPath]: permissionApiSlice.reducer,
		[bonusApiSlice.reducerPath]: bonusApiSlice.reducer,
		[accountApiSlice.reducerPath]: accountApiSlice.reducer,
		[operatorApiSlice.reducerPath]: operatorApiSlice.reducer,
		[dashboardApiSlice.reducerPath]: dashboardApiSlice.reducer,
		[logsSlice.reducerPath]: logsSlice.reducer,
		[systemSettingsSlice.reducerPath]: systemSettingsSlice.reducer,
		[currencyApiSlice.reducerPath]: currencyApiSlice.reducer,
		[leaderboardApiSlice.reducerPath]: leaderboardApiSlice.reducer,
		[cashbackApiSlice.reducerPath]: cashbackApiSlice.reducer,
		[reportingApiSlice.reducerPath]: reportingApiSlice.reducer,
		[chutApiSlice.reducerPath]: chutApiSlice.reducer,
		[campaignApiSlice.reducerPath]: campaignApiSlice.reducer,
		[riskPlayersListApiSlice.reducerPath]: riskPlayersListApiSlice.reducer,
		[storageManagementApiSlice.reducerPath]: storageManagementApiSlice.reducer,
		[recipientProfilersApiSlice.reducerPath]: recipientProfilersApiSlice.reducer,
		[recipientListsApiSlice.reducerPath]: recipientListsApiSlice.reducer,
		[cachedApiSlice.reducerPath]: cachedApiSlice.reducer,
		[playLinkApiSlice.reducerPath]: playLinkApiSlice.reducer,
		[licenseSlice.reducerPath]: licenseSlice.reducer,
		[alertsApiSlice.reducerPath]: alertsApiSlice.reducer,
		[maxWinnersApiSlice.reducerPath]: maxWinnersApiSlice.reducer,
		[gameSkinningApiSlice.reducerPath]: gameSkinningApiSlice.reducer,
		[rtpMonitoringSlice.reducerPath]: rtpMonitoringSlice.reducer,
		currencies: currenciesSlice.reducer
	},
	middleware: getDefaultMiddleware => {
		return getDefaultMiddleware().concat(
			rulesApiSlice.middleware,
			translationApiSlice.middleware,
			partnersApiSlice.middleware,
			gamesApiSlice.middleware,
			drawApiSlice.middleware,
			permissionApiSlice.middleware,
			bonusApiSlice.middleware,
			accountApiSlice.middleware,
			operatorApiSlice.middleware,
			dashboardApiSlice.middleware,
			logsSlice.middleware,
			systemSettingsSlice.middleware,
			currencyApiSlice.middleware,
			leaderboardApiSlice.middleware,
			cashbackApiSlice.middleware,
			reportingApiSlice.middleware,
			chutApiSlice.middleware,
			campaignApiSlice.middleware,
			riskPlayersListApiSlice.middleware,
			storageManagementApiSlice.middleware,
			recipientProfilersApiSlice.middleware,
			recipientListsApiSlice.middleware,
			cachedApiSlice.middleware,
			playLinkApiSlice.middleware,
			licenseSlice.middleware,
			alertsApiSlice.middleware,
			maxWinnersApiSlice.middleware,
			gameSkinningApiSlice.middleware,
			rtpMonitoringSlice.middleware,
			rtkQueryErrorMiddleware
		);
	}
});

setupListeners(store.dispatch);
