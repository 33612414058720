import { useCallback } from "react";
import { PlusOutlined } from "@ant-design/icons";
import HeaderFilters from "common/HeaderFilters";
import { useFilters } from "hooks/useFilters";
import { notification } from "antd";
import { useModal } from "hooks/useModal";
import { useTable } from "hooks/useTable";
import { mutateSelectValues } from "utils/helpers";
import { mockedUnitIds } from "utils/models";
import TableWithPagination from "common/TableWithPagination";
import CustomModal from "common/Modal";
import {
	useEditChatStatusMutation,
	useGetChatsListFiltersQuery,
	useGetChatsListMutation
} from "app/services/chutApiSlice";
import PageActionButton from "common/PageActionButton";
import { tableFilters, modalTypes, tableColumns } from "./constants";
import { ChatConfigModal } from "./ChatConfigModal";

function ChatConfiguration() {
	const [filtersQueryData, { selectedFiltersGroup, setSelectedFiltersGroup }, handleSelectChange] =
		useFilters(useGetChatsListFiltersQuery);
	const [getTableData, { isLoading, data, pageSize, pageIndex, handlePageChange, reset }] = useTable(
		useGetChatsListMutation,
		filtersQueryData
	);
	const [modals, openModal, closeModal] = useModal();
	const configModalData = modals[modalTypes.config];

	const [editChatStatus, { isLoading: statusLoading }] = useEditChatStatusMutation();

	const handleReset = useCallback(() => {
		reset();
		setSelectedFiltersGroup({});
	}, [reset]);

	const handleApply = useCallback(() => {
		const { chatNames, partners, gameTypes, statuses } = selectedFiltersGroup;

		const payload = {
			chatIds: chatNames,
			partnerIds: partners,
			gameTypeIds: gameTypes,
			chatStatus: +statuses
		};

		getTableData(payload);
	}, [selectedFiltersGroup, filtersQueryData]);

	const handleActivateDeactivate = () => {
		const { id, status } = modals[modalTypes.activeDeactive];
		editChatStatus({ id, status: status === 1 ? 0 : 1 }).then(res => {
			if (res.data.hasError === false) {
				handleApply();
				closeModal(modalTypes.activeDeactive);
				notification.success({
					message: "Successfuly updated!"
				});
			}
		});
	};

	return (
		<section className="content-container pt-24">
			<HeaderFilters
				withReset
				withSubmit
				filtersComponentData={tableFilters}
				filtersQueryData={filtersQueryData}
				handleReset={handleReset}
				handleApply={handleApply}
				handleChange={handleSelectChange}
				selectedFiltersGroup={selectedFiltersGroup}
				setSelectedFiltersGroup={setSelectedFiltersGroup}
				disabledApplay={isLoading}
			>
				<PageActionButton
					text="New Chat"
					type="primary"
					actionId={1}
					parentUnitId={mockedUnitIds.Chat}
					unitId={mockedUnitIds.ChatConfiguration}
					icon={<PlusOutlined />}
					handleClick={() => openModal(modalTypes.config, { isEdit: false })}
				/>
			</HeaderFilters>

			<TableWithPagination
				isLoading={isLoading}
				columns={tableColumns({ openModal })}
				data={data?.chats}
				totalCount={data?.totalCount}
				pageSize={pageSize}
				currentPage={pageIndex}
				setCurrentPage={handlePageChange}
				scroll={{ y: 500 }}
				rowKey="id"
			/>

			{configModalData?.open && (
				<CustomModal
					open
					title={configModalData?.isEdit ? "Edit Chat" : "New Chat"}
					onCancel={() => closeModal(modalTypes.config)}
					footer={null}
					modalWidth={860}
					bodyStyle={{ padding: "24px 42px 0" }}
					className="centered-modal-footer"
					centered
					body={
						<ChatConfigModal
							{...configModalData}
							filtersQueryData={filtersQueryData}
							closeModal={() => closeModal(modalTypes.config)}
							handleApply={handleApply}
						/>
					}
				/>
			)}

			<CustomModal
				open={modals[modalTypes.activeDeactive]?.open}
				onOk={handleActivateDeactivate}
				onCancel={() => closeModal(modalTypes.activeDeactive)}
				okText="Yes"
				okButtonProps={{ disabled: statusLoading }}
				bodyStyle={{
					textAlign: "center",
					paddingTop: 60
				}}
				modalWidth={400}
				body={
					<>
						<p className="muted-text mb-0">
							Are you sure you want to {modals[modalTypes.activeDeactive]?.status === 1 ? "deactivate" : "activate"}
						</p>
						<span>{modals[modalTypes.activeDeactive]?.name}</span>?
					</>
				}
			/>
		</section>
	);
}

export default ChatConfiguration;
