export const pageSize = 10;
export const IP_ADDRESS_MASK_4 = "0[0][0].0[0][0].0[0][0].0[0][0]";
export const IPv4 =
	"^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|0)\\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|0)\\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|0)\\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|0)$";
export const IPv6 =
	"(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))";

export const first_wheel_COLOR_IDS = {
	1: "red",
	2: "purple",
	3: "blue",
	4: "orange",
	5: "skyblue"
};

export const second_wheel_COLOR_IDS = {
	1: "skyblue",
	2: "orange",
	3: "blue",
	4: "purple",
	5: "red"
};
export const JW_COLOR_IDS = {
	1: "blue",
	2: "orange",
	3: "skyblue",
	4: "red",
	5: "purple"
};

export const errorEnums = {
	"MaxBet": "maxBet",
	"MinBet": "minBet",
	"MaxWin": "maxWin",
	"DefaultBet": "defaultBet",
	"QuickBets": "quickBets",
	"Minimal Quick Bet": "quickBets",
	"isDefault": "isDefault",
	"Name": "name"
};

export const rtlLanguages = ["ir", "sa"];
export const rtlLanguagesIds = [17, 2];
