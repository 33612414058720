import axios from "axios";
import {
	ACCOUNT,
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	CHANGE_PASSWORD,
	LOGIN,
	VERIFY,
	LOG_OUT,
	CONFIRM_EMAIL,
	SET_PASSWORD
} from "app/constants";
import storage from "utils/storage";

function makeApiRequest(url, options, verifyToken) {
	options = {
		...options,
		url: `${process.env.REACT_APP_BASE_URL}${ACCOUNT}/${url}`,
		headers: {
			"Access-Control-Allow-Origin": "*"
		},
		cache: "no-cache"
	};

	if (verifyToken) {
		options.headers.token = verifyToken;
	}

	return new Promise((resolve, reject) => {
		axios(options)
			.then(response => resolve(response))
			.catch(error => {
				if (error?.response) {
					reject(error?.response);
				}
				reject(error);
			});
	});
}

export function logOut() {
	const token = storage?.getItem("token");
	return makeApiRequest(LOG_OUT, { method: "POST" }, token);
}

export function loginUser(data) {
	return makeApiRequest(LOGIN, { method: "POST", data });
}

export function forgotPassword(data) {
	return makeApiRequest(FORGOT_PASSWORD, { method: "POST", data });
}

export function resetPassword(data) {
	return makeApiRequest(RESET_PASSWORD, { method: "POST", data });
}

export function setPassword(data) {
	return makeApiRequest(SET_PASSWORD, { method: "POST", data });
}

export function confirmEmail(data) {
	return makeApiRequest(CONFIRM_EMAIL, { method: "POST", data });
}

export function changePassword(data) {
	const token = storage?.getItem("token");
	return makeApiRequest(CHANGE_PASSWORD, { method: "POST", data }, token);
}

export function verify2FACode(data, token) {
	return makeApiRequest(VERIFY, { method: "POST", data }, token);
}
